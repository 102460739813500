body, svg text, #app {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

body {
    background-color: #fff;
}

@media screen and (prefers-color-scheme: dark) {
    html, body {
        background-color: #121212;
        color-scheme: dark;
    }
}

.monospace, .mono, pre {
    font-family: ui-monospace, Inconsolata, Consolas, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

.loading {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.input-group .tooltip label {
    max-width: 100%;
}

main.content {
    max-width: 1536px;
}

@media print {
    nav.v-navigation-drawer, header.v-app-bar {
        display: none !important;
    }
    main {
        padding: 1cm !important;
    }
    .v-card, .v-sheet {
        border: 0px solid transparent !important;
        outline: 0px solid transparent !important;
        box-shadow: none !important;
    }
}

.theme--dark.v-card, .theme--dark.v-data-table, .theme--dark.v-sheet {
    background-color: #2e2e2e;
}